import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'

import {categoriesStyle} from '../styles/categories-cz-style'
import Category from './category-cz'

import { setSelectedTab, setTabs } from 'jnpsoft-react-utilities/dist/AppBar/redux/actions';

const Categories = ({data, handleResourceDownload, handleResourceSignature, viewTable, selectedTabIndex, setSelectedTab, setTabs}) => {
    const categories = data ? Object.entries(data.sections) : []
  
    useEffect(() => {
        setTabs(categories.map( (category, index) => ({value : index, label : category[0]})))
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" sx={categoriesStyle.root}>
            {
                categories.map((category, index) => (
                    <Category 
                        key={index} 
                        hidden={selectedTabIndex !== index}
                        category={category} 
                        id={`category-tabpanel-${index}`}
                        viewTable={viewTable}
                        handleResourceDownload={handleResourceDownload}
                        handleResourceSignature={handleResourceSignature}
                    />
                ))
            }
        </Box>
    )
}

const mapStateToProps = state => ({
    selectedTabIndex: state.appBarTabs.selectedTabIndex,
})

const dispatchToProps = dispatch => ({
    setSelectedTab : selectedTab => dispatch(setSelectedTab(selectedTab)),
    setTabs : tabs => dispatch(setTabs(tabs)),
})

export default connect(mapStateToProps, dispatchToProps)(Categories)