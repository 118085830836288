import React from 'react'

import Section from './section-cz'
import { categoryStyle } from '../styles/category-cz-style'
import { Box } from '@mui/material'
const SearchDisplayResources = ({ resources }) => {

    const section = {
        name: 'Search',
        description: 'List Resources',
        resources: resources
    }
    return (
        <Box sx={categoryStyle.root}>
            <Section section={section} />
        </Box>
    )
}

export default SearchDisplayResources