// import 'core-js/fn/string/includes'
import 'core-js/stable'
// import 'core-js/es/array'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/app'

ReactDOM.render(
    <App />,
    document.getElementById('root')
);