import React from 'react'
import { Typography, Grid, Box } from '@mui/material'

import {sectionStyle} from '../styles/section-cz-style'
import ResourceTable from './resource-table-cz'
import Resource from './resource-cz'

const Section = ({section, viewTable, handleResourceDownload, handleResourceSignature}) => {
    
    const { name, description, resources } = section
    return (
        <Box sx={sectionStyle.root}>
            <header style={sectionStyle.header}>
                <Typography variant="h5">{name}</Typography>
                <Typography variant="subtitle1">{description}</Typography>
            </header>
            <Box sx={sectionStyle.section}>
                {
                    viewTable
                    ? (<ResourceTable resources={resources} sectionImage={section.image} handleResourceDownload={handleResourceDownload} handleResourceSignature={handleResourceSignature} />) 
                    : (
                        <Grid container sx={sectionStyle.root} spacing={3}>
                            {
                                resources.map((resource, index) => (
                                    <Resource key={index} resource={resource} sectionImage={section.image} handleResourceDownload={handleResourceDownload} handleResourceSignature={handleResourceSignature}/>
                                ))
                            }
                        </Grid>
                    )
                }
            </Box>

        </Box>
    )
}

export default Section