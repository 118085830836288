export const sectionStyle = {
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        padding: (theme) => theme.spacing(1, 1, 2, 1)
    },
    header: {
        borderBottom: '4px solid #00796b',
        borderRadius: '5px',
        marginBottom:'8px'
    },
    section: {
        // padding: theme.spacing(1, 1, 1, 5),
        // margin: theme.spacing(1, 1, 1, 1)
    },
}