import React, { useState } from 'react'
import { Paper, Grid, ButtonBase, Typography, Link, Popover, Box } from '@mui/material'

import {resourceStyle} from '../styles/resource-cz-style'

const Resource = ({resource, sectionImage, handleResourceDownload,  handleResourceSignature}) => {

    const [anchorElFileName, setAnchorElFileName] = useState(null);
    const [anchorElSignature, setAnchorElSigrature] = useState(null);
    const [signature, setSignature] = useState('');

    const { fileName, image, size, date } = resource
    const img = image ? image.image : sectionImage ? sectionImage.image : '/assets/JNPSoft Squares RGB.png'
    

    const handleFileNameOpen= (event) => {
        setAnchorElFileName(event.currentTarget)
    }

    const handleFileNameClose  = () => {
        setAnchorElFileName(null)
    }

    const handleSignatureClick = (event) => {
        setAnchorElSigrature(event.currentTarget);
        handleResourceSignature({...resource, setResourceSignature: ({signature}) => setSignature(signature)});
    }

    const handleSignatureClose  = () => {
        setAnchorElSigrature(null);
        setSignature("");
    }

    const openFileName = Boolean(anchorElFileName)
    const openSignature = Boolean(anchorElSignature)
    const idSignaturePopover = openSignature ? 'signature-popover' : undefined

    return (
        <Grid item xs={12} sm={4} sx={resourceStyle.resource}>
            <Popover 
                id={idSignaturePopover}
                open={openSignature} 
                anchorEl={anchorElSignature}
                onClose={handleSignatureClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper>
                    <Typography sx={resourceStyle.typography}>{signature}</Typography>
                </Paper>
            </Popover>
            <Popover
                id="mouse-over-filename-popover"
                sx={resourceStyle.popover}
                classes={{
                    paper: resourceStyle.paperpopover,
                }}
                open={openFileName}
                anchorEl={anchorElFileName}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleFileNameClose}
                disableRestoreFocus
            >
                <Typography>{fileName}</Typography>
            </Popover>
            <Paper sx={resourceStyle.paper} >
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <ButtonBase sx={resourceStyle.image} onClick={() => handleResourceDownload(resource)}>
                            <Box component="img" sx={resourceStyle.img} alt={fileName} src={img} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm={8} container>
                        <Grid item xs container direction="column" spacing={2} wrap="nowrap">
                            <Grid item xs zeroMinWidth>
                                <Typography 
                                    gutterBottom 
                                    variant="subtitle1"
                                    noWrap
                                    aria-owns={openFileName ? 'mouse-over-filename-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handleFileNameOpen}
                                    onMouseLeave={handleFileNameClose}
                                >
                                    {fileName}
                                </Typography>
                                <Typography gutterBottom variant="body2" noWrap>
                                    <Link component="button" onClick={handleSignatureClick}>Signature (SHA256)</Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">{date}</Typography>
                                <Typography variant="subtitle2">{size}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" >
                                    <Link component="button" onClick={() => handleResourceDownload(resource)} >Download</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        
    )
}

export default Resource