import React, { useState } from 'react'
import MaterialTable from 'material-table'
import { Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextareaAutosize, Grid, Chip } from '@mui/material'
import { CloudDownload as CloudDownloadIcon, VerifiedUser as VerifiedUserIcon, FolderOpen as FolderOpenIcon } from '@mui/icons-material'

const options = {
    actionsColumnIndex: -1,
    search: false,
    headerStyle: {
        backgroundColor: '#003061',
        color: '#FFF'
    },
    rowStyle: {
        
    },
    grouping: false,
    paging: false,
    toolbar: false,
    filtering: false,
    selection: false,
    sorting: false,
    draggable: false
}

const SignatureDialog = ({resource, open, handleClose}) => {
    
    if(!open) return <div></div>

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle >{`${resource.fileName} - Signature (SHA256)`}</DialogTitle>
            <DialogContent>
                <TextareaAutosize aria-label="signature" rowsMin={3} placeholder="Signature (SHA256)" value={resource.signature} style={{width: '100%', resize: 'vertical'}} />
            </DialogContent>
            <DialogActions><Button onClick={handleClose} color="primary" autoFocus>Close</Button></DialogActions>
        </Dialog>
    )
}


const ResourceTable = ({title = '', resources, sectionImage, handleResourceDownload,  handleResourceSignature}) => {
    const [resourceSignature, setResourceSignature] = useState(null)
    const hasManyDirectory = () => {
        const directories = resources.map(r => r.companyDirectory).filter((v, i, a) => a.indexOf(v) === i)
        return directories.length > 1;
    }
    const columns = [
        { 
            title: 'Image', 
            field: 'image', 
            render: rowData => (
                <Avatar 
                    alt={rowData.fileName} 
                    src={rowData.image ? rowData.image.image : sectionImage ? sectionImage.image : '/assets/JNPSoft Squares RGB.png'} 
                    variant="rounded"
                />
            ),
            width: '5%'
        },
        {
            title: 'File Name',
            field: 'fileName',
            width: '45%',
            render: rowData => (
                <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                    <Grid item>{rowData.fileName}</Grid>
                    {
                        Boolean(rowData.companyDirectory) && rowData.companyDirectory !== '__nodir__' && hasManyDirectory() &&
                        <Grid item><Chip icon={<FolderOpenIcon />} label={rowData.companyDirectory} size="small" /></Grid>
                    }
                </Grid>
            )
        },
        {
            title: 'Description',
            field: 'description',
            width: '30%'
        },
        {
            title: 'Date',
            field: 'date',
            width: '15%'
        },
        {
            title: 'Size',
            field: 'size',
            width: '10%'
        }
    ]

    const handleOpenSignatureDialog = (event, resource) => {
        console.log(resource);
        handleResourceSignature({...resource, setResourceSignature})
    }

    const handleCloseSignatureDialog = () => {
        setResourceSignature(null)
    }

    const actions = [
        {
            icon: CloudDownloadIcon,
            tooltip: 'Download',
            onClick : (event, resource) => handleResourceDownload(resource)
        },
        {
            icon: VerifiedUserIcon,
            tooltip: 'Signature',
            onClick : handleOpenSignatureDialog
        }
    ]

    const openSignatureDialog = Boolean(resourceSignature)
    return (
        <div>
            <SignatureDialog open={openSignatureDialog} handleClose={handleCloseSignatureDialog} resource={resourceSignature} />
            <MaterialTable 
                title={title}
                columns={columns}
                data={resources}
                actions={actions}
                options={options}
            />
        </div>
    )
}

export default ResourceTable