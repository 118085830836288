export const resourceStyle  = {
    paper: {
        padding: (theme) => theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        backgroundColor: '#f5f5f5',
        height: '100%'
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    typography: {
        padding: (theme) => theme.spacing(2),
    },
    popover: {
        pointerEvents: 'none',
    },
    paperpopover: {
        padding: (theme) => theme.spacing(1),
    },
    resource: {
        minHeight: 180
    }
}