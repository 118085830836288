import React from 'react'
import { Box } from '@mui/material'

import {categoryStyle} from '../styles/category-cz-style'
import Section from './section-cz'

const Category = ({id, category, viewTable, handleResourceDownload, handleResourceSignature, ...other}) => {

    const [name, sections] = category 


    return (
        <Box sx={categoryStyle.root} id={id} {...other} height="100%">
            {
                sections.filter((section) => section.resources.length > 0).map((section, index) => (
                    <Section key={index} section={section} viewTable={viewTable} handleResourceDownload={handleResourceDownload} handleResourceSignature={handleResourceSignature} />
                ))
            }
        </Box>
    )
}

export default Category