import React, { useState, useEffect } from 'react'
import { trackPromise } from 'react-promise-tracker'

//material-ui
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//js-file-download
import FileDownload  from 'js-file-download'

import Categories from './categories-cz'
import SearchDisplayResources from './search-display-resources-cz'
import {clientZoneStyle} from '../styles/client-zone-style'

import {AxiosInstance} from 'jnpsoft-react-utilities/dist/AppBar';

const extractAllResources = (sections) => {
    return [].concat.apply([], Object.values(sections)).map(section => section.resources).reduce((acc, val) => acc.concat(val), [])
}

const displayErrorProperEquivalence = {
    'threshold-limit-part': {
        title: 'Search limited',
        text: 'The search is limited to 50 parts. Please try again with the required number.',
        button: 'I understand'
    }
}

const ClientZone = () => {

    const [api, setApi] =  useState(null)
    const [loading, setLoading] =  useState(true)
    const [downloading, setDownloading] = useState(false);
    const [signaturing, setSignaturing] = useState(false);
    const [filterResources, setFilterResources] =  useState([])
    const [error, setError] =  useState(null)
    const [viewTable, setViewTable] = useState(true)
    const initialResources = api ? extractAllResources(api.sections) : []

    useEffect(() => {
        if(!api) {
            trackPromise(AxiosInstance.get(process.env.REACT_APP_CLIENT_ZONE_API_URL).then(response => {
                setApi(response.data)
                setLoading(false)
            }).catch(error => {
                console.log(error.response)
                setError(error.response)
                setLoading(false)
            }))
        }
    }, [api])

    const handleSearchChange = (event) => {
        const { value } = event.target
        if(value.length > 0) {
            let resources = initialResources.filter((resource) => {
                return resource.fileName.toLowerCase().search(value.toLowerCase()) !== -1
            })
            setFilterResources(resources)
        }
        else setFilterResources([])
    }

    const handleResourceDownload = ({fileName, sectionId, encodedFileName, companyDirectory}) => {
        const downloadHref = `${process.env.REACT_APP_CLIENT_ZONE_API_URL}/download?s=${sectionId}&companyDirectory=${companyDirectory}&q=${encodedFileName}`
        setDownloading(true);
        trackPromise(AxiosInstance.get(downloadHref, {responseType: 'arraybuffer'}).then(response => {
            FileDownload(response.data, fileName);
            setDownloading(false);
        }).catch(error => {
            console.log(error.response);
            setError(error.response);
            setDownloading(false);
        }))
    }

    const handleResourceSignature = ({fileName, sectionId, companyDirectory, encodedFileName, setResourceSignature}) => {
        setSignaturing(true)
        const downloadHref = `${process.env.REACT_APP_CLIENT_ZONE_API_URL}/signature/${sectionId}/${companyDirectory}/${encodedFileName}`;
        trackPromise(AxiosInstance.get(downloadHref)
        .then(response => {
            setResourceSignature({fileName: fileName, signature: response.data});
            setSignaturing(false);
        }).catch(error => {
            console.log(error.response);
            setError(error.response);
            setSignaturing(false);
        }))
    }

    const handleToggleViewTable = () => {
        setViewTable(!viewTable)
    }
    const handleCloseModalError = () => setError(null)
    const displayErrorProper = error ? 
        displayErrorProperEquivalence[error.data.message] ? 
        displayErrorProperEquivalence[error.data.message] : 
        {title: 'Error', text: error.data.message, button: 'Close'} : 
        null

    return (
        <Box height="100%">
            <Box height="100%" sx={clientZoneStyle.paper} >
                {
                    filterResources.length > 0 ? (
                        <SearchDisplayResources resources={filterResources} handleResourceDownload={handleResourceDownload} handleResourceSignature={handleResourceSignature} />
                    ) : (
                        !loading && <Categories data={api} handleResourceDownload={handleResourceDownload} handleResourceSignature={handleResourceSignature} viewTable={viewTable} />
                    )
                }
            </Box>
            <Backdrop sx={clientZoneStyle.backdrop} open={loading || downloading || signaturing} >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                    {
                        (downloading || signaturing) &&
                        <Box paddingTop="16px">
                            <Typography>{downloading ? "We're preparing your download" : "We're getting your signature"}</Typography>
                        </Box>
                    }
                </Box>
            </Backdrop>
            {
                error && (
                    <Dialog
                        open={Boolean(error)}
                        onClose={handleCloseModalError}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{displayErrorProper.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {displayErrorProper.text}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModalError} color="primary">
                            {displayErrorProper.button}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </Box>
    )
}

export default ClientZone