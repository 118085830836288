import React from 'react';
import ClientZone from './client-zone'

import {AuthProvider} from 'jnpsoft-react-utilities/dist/AppBar';

const App = () => {
  return (
      <AuthProvider>
          <ClientZone />
      </AuthProvider>
  );
}

export default App;