export const categoriesStyle = {
    root: {
        flexGrow: 1,
        width: '100%',
        paddingTop: (theme) => theme.spacing(6)
    },
    fab: {
        position: 'absolute',
        bottom: (theme) => theme.spacing(2),
        right: (theme) => theme.spacing(2),
        backgroundColor: '#00796b'
    }
}